import * as React from "react";

const Form = () => {
  React.useEffect(() => {
    // @ts-ignore
    !(function (t) {
      const e = {};
      function r(n) {
        if (e[n]) return e[n].exports;
        const o = (e[n] = { i: n, l: !1, exports: {} });
        return t[n].call(o.exports, o, o.exports, r), (o.l = !0), o.exports;
      }
      (r.m = t),
        (r.c = e),
        (r.d = function (t, e, n) {
          r.o(t, e) || Object.defineProperty(t, e, { enumerable: !0, get: n });
        }),
        (r.r = function (t) {
          "undefined" != typeof Symbol &&
            Symbol.toStringTag &&
            Object.defineProperty(t, Symbol.toStringTag, { value: "Module" }),
            Object.defineProperty(t, "__esModule", { value: !0 });
        }),
        (r.t = function (t, e) {
          if ((1 & e && (t = r(t)), 8 & e)) return t;
          if (4 & e && "object" == typeof t && t && t.__esModule) return t;
          const n = Object.create(null);
          if (
            (r.r(n),
            Object.defineProperty(n, "default", { enumerable: !0, value: t }),
            2 & e && "string" != typeof t)
          )
            for (const o in t)
              r.d(
                n,
                o,
                function (e) {
                  return t[e];
                }.bind(null, o)
              );
          return n;
        }),
        (r.n = function (t) {
          const e =
            t && t.__esModule
              ? function () {
                  return t.default;
                }
              : function () {
                  return t;
                };
          return r.d(e, "a", e), e;
        }),
        (r.o = function (t, e) {
          return Object.prototype.hasOwnProperty.call(t, e);
        }),
        (r.p = "/js/compiled/"),
        r((r.s = 0));
    })([
      function (t, e, r) {
        r(1), (t.exports = r(3));
      },
      function (t, e, r) {
        (function (t) {
          function e(t) {
            return (e =
              "function" == typeof Symbol && "symbol" == typeof Symbol.iterator
                ? function (t) {
                    return typeof t;
                  }
                : function (t) {
                    return t &&
                      "function" == typeof Symbol &&
                      t.constructor === Symbol &&
                      t !== Symbol.prototype
                      ? "symbol"
                      : typeof t;
                  })(t);
          }
          const r = (function (t) {
            "use strict";
            let r,
              n = Object.prototype,
              o = n.hasOwnProperty,
              i = "function" == typeof Symbol ? Symbol : {},
              a = i.iterator || "@@iterator",
              c = i.asyncIterator || "@@asyncIterator",
              u = i.toStringTag || "@@toStringTag";
            function l(t, e, r, n) {
              const o = e && e.prototype instanceof m ? e : m,
                i = Object.create(o.prototype),
                a = new P(n || []);
              return (
                (i._invoke = (function (t, e, r) {
                  let n = s;
                  return function (o, i) {
                    if (n === p)
                      throw new Error("Generator is already running");
                    if (n === y) {
                      if ("throw" === o) throw i;
                      return T();
                    }
                    for (r.method = o, r.arg = i; ; ) {
                      const a = r.delegate;
                      if (a) {
                        const c = S(a, r);
                        if (c) {
                          if (c === d) continue;
                          return c;
                        }
                      }
                      if ("next" === r.method) r.sent = r._sent = r.arg;
                      else if ("throw" === r.method) {
                        if (n === s) throw ((n = y), r.arg);
                        r.dispatchException(r.arg);
                      } else "return" === r.method && r.abrupt("return", r.arg);
                      n = p;
                      const u = f(t, e, r);
                      if ("normal" === u.type) {
                        if (((n = r.done ? y : h), u.arg === d)) continue;
                        return { value: u.arg, done: r.done };
                      }
                      "throw" === u.type &&
                        ((n = y), (r.method = "throw"), (r.arg = u.arg));
                    }
                  };
                })(t, r, a)),
                i
              );
            }
            function f(t, e, r) {
              try {
                return { type: "normal", arg: t.call(e, r) };
              } catch (t) {
                return { type: "throw", arg: t };
              }
            }
            t.wrap = l;
            var s = "suspendedStart",
              h = "suspendedYield",
              p = "executing",
              y = "completed",
              d = {};
            function m() {}
            function v() {}
            function g() {}
            let w = {};
            w[a] = function () {
              return this;
            };
            const b = Object.getPrototypeOf,
              x = b && b(b(k([])));
            x && x !== n && o.call(x, a) && (w = x);
            const L = (g.prototype = m.prototype = Object.create(w));
            function E(t) {
              ["next", "throw", "return"].forEach(function (e) {
                t[e] = function (t) {
                  return this._invoke(e, t);
                };
              });
            }
            function j(t) {
              let r;
              this._invoke = function (n, i) {
                function a() {
                  return new Promise(function (r, a) {
                    !(function r(n, i, a, c) {
                      const u = f(t[n], t, i);
                      if ("throw" !== u.type) {
                        const l = u.arg,
                          s = l.value;
                        return s && "object" === e(s) && o.call(s, "__await")
                          ? Promise.resolve(s.__await).then(
                              function (t) {
                                r("next", t, a, c);
                              },
                              function (t) {
                                r("throw", t, a, c);
                              }
                            )
                          : Promise.resolve(s).then(
                              function (t) {
                                (l.value = t), a(l);
                              },
                              function (t) {
                                return r("throw", t, a, c);
                              }
                            );
                      }
                      c(u.arg);
                    })(n, i, r, a);
                  });
                }
                return (r = r ? r.then(a, a) : a());
              };
            }
            function S(t, e) {
              const n = t.iterator[e.method];
              if (n === r) {
                if (((e.delegate = null), "throw" === e.method)) {
                  if (
                    t.iterator.return &&
                    ((e.method = "return"),
                    (e.arg = r),
                    S(t, e),
                    "throw" === e.method)
                  )
                    return d;
                  (e.method = "throw"),
                    (e.arg = new TypeError(
                      "The iterator does not provide a 'throw' method"
                    ));
                }
                return d;
              }
              const o = f(n, t.iterator, e.arg);
              if ("throw" === o.type)
                return (
                  (e.method = "throw"), (e.arg = o.arg), (e.delegate = null), d
                );
              const i = o.arg;
              return i
                ? i.done
                  ? ((e[t.resultName] = i.value),
                    (e.next = t.nextLoc),
                    "return" !== e.method && ((e.method = "next"), (e.arg = r)),
                    (e.delegate = null),
                    d)
                  : i
                : ((e.method = "throw"),
                  (e.arg = new TypeError("iterator result is not an object")),
                  (e.delegate = null),
                  d);
            }
            function O(t) {
              const e = { tryLoc: t[0] };
              1 in t && (e.catchLoc = t[1]),
                2 in t && ((e.finallyLoc = t[2]), (e.afterLoc = t[3])),
                this.tryEntries.push(e);
            }
            function _(t) {
              const e = t.completion || {};
              (e.type = "normal"), delete e.arg, (t.completion = e);
            }
            function P(t) {
              (this.tryEntries = [{ tryLoc: "root" }]),
                t.forEach(O, this),
                this.reset(!0);
            }
            function k(t) {
              if (t) {
                const e = t[a];
                if (e) return e.call(t);
                if ("function" == typeof t.next) return t;
                if (!isNaN(t.length)) {
                  let n = -1,
                    i = function e() {
                      for (; ++n < t.length; )
                        if (o.call(t, n))
                          return (e.value = t[n]), (e.done = !1), e;
                      return (e.value = r), (e.done = !0), e;
                    };
                  return (i.next = i);
                }
              }
              return { next: T };
            }
            function T() {
              return { value: r, done: !0 };
            }
            return (
              (v.prototype = L.constructor = g),
              (g.constructor = v),
              (g[u] = v.displayName = "GeneratorFunction"),
              (t.isGeneratorFunction = function (t) {
                const e = "function" == typeof t && t.constructor;
                return (
                  !!e &&
                  (e === v || "GeneratorFunction" === (e.displayName || e.name))
                );
              }),
              (t.mark = function (t) {
                return (
                  Object.setPrototypeOf
                    ? Object.setPrototypeOf(t, g)
                    : ((t.__proto__ = g),
                      u in t || (t[u] = "GeneratorFunction")),
                  (t.prototype = Object.create(L)),
                  t
                );
              }),
              (t.awrap = function (t) {
                return { __await: t };
              }),
              E(j.prototype),
              (j.prototype[c] = function () {
                return this;
              }),
              (t.AsyncIterator = j),
              (t.async = function (e, r, n, o) {
                const i = new j(l(e, r, n, o));
                return t.isGeneratorFunction(r)
                  ? i
                  : i.next().then(function (t) {
                      return t.done ? t.value : i.next();
                    });
              }),
              E(L),
              (L[u] = "Generator"),
              (L[a] = function () {
                return this;
              }),
              (L.toString = function () {
                return "[object Generator]";
              }),
              (t.keys = function (t) {
                const e = [];
                for (const r in t) e.push(r);
                return (
                  e.reverse(),
                  function r() {
                    for (; e.length; ) {
                      const n = e.pop();
                      if (n in t) return (r.value = n), (r.done = !1), r;
                    }
                    return (r.done = !0), r;
                  }
                );
              }),
              (t.values = k),
              (P.prototype = {
                constructor: P,
                reset: function (t) {
                  if (
                    ((this.prev = 0),
                    (this.next = 0),
                    (this.sent = this._sent = r),
                    (this.done = !1),
                    (this.delegate = null),
                    (this.method = "next"),
                    (this.arg = r),
                    this.tryEntries.forEach(_),
                    !t)
                  )
                    for (const e in this)
                      "t" === e.charAt(0) &&
                        o.call(this, e) &&
                        !isNaN(+e.slice(1)) &&
                        (this[e] = r);
                },
                stop: function () {
                  this.done = !0;
                  const t = this.tryEntries[0].completion;
                  if ("throw" === t.type) throw t.arg;
                  return this.rval;
                },
                dispatchException: function (t) {
                  if (this.done) throw t;
                  const e = this;
                  function n(n, o) {
                    return (
                      (c.type = "throw"),
                      (c.arg = t),
                      (e.next = n),
                      o && ((e.method = "next"), (e.arg = r)),
                      !!o
                    );
                  }
                  for (let i = this.tryEntries.length - 1; i >= 0; --i) {
                    var a = this.tryEntries[i],
                      c = a.completion;
                    if ("root" === a.tryLoc) return n("end");
                    if (a.tryLoc <= this.prev) {
                      const u = o.call(a, "catchLoc"),
                        l = o.call(a, "finallyLoc");
                      if (u && l) {
                        if (this.prev < a.catchLoc) return n(a.catchLoc, !0);
                        if (this.prev < a.finallyLoc) return n(a.finallyLoc);
                      } else if (u) {
                        if (this.prev < a.catchLoc) return n(a.catchLoc, !0);
                      } else {
                        if (!l)
                          throw new Error(
                            "try statement without catch or finally"
                          );
                        if (this.prev < a.finallyLoc) return n(a.finallyLoc);
                      }
                    }
                  }
                },
                abrupt: function (t, e) {
                  for (let r = this.tryEntries.length - 1; r >= 0; --r) {
                    const n = this.tryEntries[r];
                    if (
                      n.tryLoc <= this.prev &&
                      o.call(n, "finallyLoc") &&
                      this.prev < n.finallyLoc
                    ) {
                      var i = n;
                      break;
                    }
                  }
                  i &&
                    ("break" === t || "continue" === t) &&
                    i.tryLoc <= e &&
                    e <= i.finallyLoc &&
                    (i = null);
                  const a = i ? i.completion : {};
                  return (
                    (a.type = t),
                    (a.arg = e),
                    i
                      ? ((this.method = "next"), (this.next = i.finallyLoc), d)
                      : this.complete(a)
                  );
                },
                complete: function (t, e) {
                  if ("throw" === t.type) throw t.arg;
                  return (
                    "break" === t.type || "continue" === t.type
                      ? (this.next = t.arg)
                      : "return" === t.type
                      ? ((this.rval = this.arg = t.arg),
                        (this.method = "return"),
                        (this.next = "end"))
                      : "normal" === t.type && e && (this.next = e),
                    d
                  );
                },
                finish: function (t) {
                  for (let e = this.tryEntries.length - 1; e >= 0; --e) {
                    const r = this.tryEntries[e];
                    if (r.finallyLoc === t)
                      return this.complete(r.completion, r.afterLoc), _(r), d;
                  }
                },
                catch: function (t) {
                  for (let e = this.tryEntries.length - 1; e >= 0; --e) {
                    const r = this.tryEntries[e];
                    if (r.tryLoc === t) {
                      const n = r.completion;
                      if ("throw" === n.type) {
                        var o = n.arg;
                        _(r);
                      }
                      return o;
                    }
                  }
                  throw new Error("illegal catch attempt");
                },
                delegateYield: function (t, e, n) {
                  return (
                    (this.delegate = {
                      iterator: k(t),
                      resultName: e,
                      nextLoc: n,
                    }),
                    "next" === this.method && (this.arg = r),
                    d
                  );
                },
              }),
              t
            );
          })("object" === e(t) ? t.exports : {});
          try {
            regeneratorRuntime = r;
          } catch (t) {
            Function("r", "regeneratorRuntime = r")(r);
          }
        }.call(this, r(2)(t)));
      },
      function (t, e) {
        t.exports = function (t) {
          return (
            t.webpackPolyfill ||
              ((t.deprecate = function () {}),
              (t.paths = []),
              t.children || (t.children = []),
              Object.defineProperty(t, "loaded", {
                enumerable: !0,
                get: function () {
                  return t.l;
                },
              }),
              Object.defineProperty(t, "id", {
                enumerable: !0,
                get: function () {
                  return t.i;
                },
              }),
              (t.webpackPolyfill = 1)),
            t
          );
        };
      },
      function (t, e) {
        let r, n;
        "function" !=
          typeof (r = window.Element
            ? window.Element.prototype
            : window.HTMLElement.prototype).matches &&
          (r.matches =
            r.msMatchesSelector ||
            r.mozMatchesSelector ||
            r.webkitMatchesSelector ||
            function (t) {
              for (
                var e = (this.document || this.ownerDocument).querySelectorAll(
                    t
                  ),
                  r = 0;
                e[r] && e[r] !== this;

              )
                ++r;
              return Boolean(e[r]);
            }),
          "function" != typeof r.closest &&
            (r.closest = function (t) {
              for (let e = this; e && 1 === e.nodeType; ) {
                if (e.matches(t)) return e;
                e = e.parentNode;
              }
              return null;
            }),
          (n = document.documentElement || document.body).getAttribute(
            "data-cm-hook"
          ) ||
            ((function (t, e, r) {
              t.addEventListener
                ? t.addEventListener(e, r)
                : t.attachEvent("on" + e, r);
            })(n, "submit", function (t) {
              const e = t.target.closest(".js-cm-form");
              e &&
                (t.preventDefault ? t.preventDefault() : (t.returnValue = !1),
                (function (t, e, r) {
                  const n = r.querySelector(".js-cm-email-input"),
                    o =
                      "email=" +
                      encodeURIComponent(n.value) +
                      "&data=" +
                      encodeURIComponent(r.getAttribute("data-id")),
                    i = new XMLHttpRequest();
                  (i.onreadystatechange = function () {
                    4 === this.readyState &&
                      200 === this.status &&
                      ((r.action = this.responseText), r.submit());
                  }),
                    i.open(t, e, !0),
                    i.setRequestHeader(
                      "Content-type",
                      "application/x-www-form-urlencoded"
                    ),
                    i.send(o);
                })(
                  "POST",
                  "https://createsend.com//t/getsecuresubscribelink",
                  e
                ));
            }),
            n.setAttribute("data-cm-hook", "1"));
      },
    ]);
  }, []);

  const uuidRef = React.useRef(null);
  React.useEffect(() => {
    const uuid = localStorage.getItem("uuid");
    if (uuidRef?.current) {
      uuidRef.current.value = uuid || "";
    }
  }, []);
  return (
    // <div>
    //   <form
    //     className="js-cm-form mb-8 space-y-4 max-w-md"
    //     id="subForm"
    //     action="https://www.createsend.com/t/subscribeerror?description="
    //     method="post"
    //     data-id="5B5E7037DA78A748374AD499497E309EA7944387C37B3DE396EDD81333E049BA12D7A6C9C982624BE067DBF96A6CFB6321A74E2385A07C3EDDB18A90CD86CB19"
    //   >
    //     <div className="space-y-4">
    //       <div>
    //         <label
    //           htmlFor="fieldName"
    //           className="block text-sm font-medium leading-5 text-primary"
    //         >
    //           Name{" "}
    //         </label>
    //         <input
    //           aria-label="Name"
    //           id="fieldName"
    //           maxLength={200}
    //           name="cm-name"
    //           className="form-input py-3 px-4 block w-full transition ease-in-out duration-150"
    //         />
    //       </div>
    //       <div>
    //         <label
    //           htmlFor="fieldEmail"
    //           className="block text-sm font-medium leading-5 text-primary"
    //         >
    //           Email{" "}
    //         </label>
    //         <input
    //           autoComplete="Email"
    //           aria-label="Email"
    //           className="js-cm-email-input qa-input-email form-input py-3 px-4 block w-full transition ease-in-out duration-150"
    //           id="fieldEmail"
    //           maxLength={200}
    //           name="cm-okujjhl-okujjhl"
    //           required={true}
    //           type="email"
    //         />
    //       </div>
    //     </div>
    //     <button
    //       className="bg-primary hover:bg-primary-alt text-white px-2 py-2 rounded w-full"
    //       type="submit"
    //     >
    //       Submit
    //     </button>
    //   </form>
    // </div>
    <div>
      <hr />
      <form
        className="js-cm-form"
        id="subForm"
        action="https://www.createsend.com/t/subscribeerror?description="
        method="post"
        data-id="5B5E7037DA78A748374AD499497E309EA7944387C37B3DE396EDD81333E049BA12D7A6C9C982624BE067DBF96A6CFB6321A74E2385A07C3EDDB18A90CD86CB19"
      >
        <div className="space-y-4">
          <div>
            <label className="block text-base font-bold tracking-wide leading-5 text-primary">
              Name
            </label>
            <input
              aria-label="Name"
              id="fieldName"
              maxLength={200}
              required={true}
              name="cm-name"
              className="form-input py-3 px-4 block w-full transition ease-in-out duration-150"
            />
          </div>
          <div>
            <label className="block text-base font-bold tracking-wide leading-5 text-primary">
              Email
            </label>
            <input
              autoComplete="Email"
              aria-label="Email"
              className="js-cm-email-input qa-input-email form-input py-3 px-4 block w-full transition ease-in-out duration-150"
              id="fieldEmail"
              maxLength={200}
              name="cm-okujjhl-okujjhl"
              required={true}
              type="email"
            />
          </div>
          <fieldset>
            <label className="block text-base font-bold tracking-wide leading-5 text-primary">
              Interested In
            </label>
            <div>
              <input
                id="17839448"
                name="cm-fo-dtiuhihi"
                type="checkbox"
                value="17839448"
              />
              <label htmlFor="17839448" className={"ml-1"}>
                Networks
              </label>
            </div>
            <div>
              <input
                id="17839449"
                name="cm-fo-dtiuhihi"
                type="checkbox"
                value="17839449"
              />
              <label htmlFor="17839449" className={"ml-1"}>
                Security
              </label>
            </div>
            <div>
              <input
                id="17839450"
                name="cm-fo-dtiuhihi"
                type="checkbox"
                value="17839450"
              />
              <label htmlFor="17839450" className={"ml-1"}>
                Digital Signage
              </label>
            </div>
            <div>
              <input
                id="17839451"
                name="cm-fo-dtiuhihi"
                type="checkbox"
                value="17839451"
              />
              <label htmlFor="17839451" className={"ml-1"}>
                Audio/Video
              </label>
            </div>
            <div>
              <input
                id="17905346"
                name="cm-fo-dtiuhihi"
                type="checkbox"
                value="17905346"
              />
              <label htmlFor="17905346" className={"ml-1"}>
                Something not covered here
              </label>
            </div>
            <div>
              <input
                id="17839452"
                name="cm-fo-dtiuhihi"
                type="checkbox"
                value="17839452"
              />
              <label htmlFor="17839452" className={"ml-1"}>
                Not interested in more information at this time
              </label>
            </div>
          </fieldset>
          <div className={"hidden"}>
            <input
              aria-label="uuid"
              id="fielddtdrltli"
              maxLength={200}
              name="cm-f-dtdrltli"
              ref={uuidRef}
            />
          </div>
        </div>
        <button
          type="submit"
          className="mt-8 bg-primary hover:bg-primary-alt text-white px-2 py-2 rounded w-full"
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default Form;
