import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { v4 as uuidv4 } from "uuid";

const defaultState = {
  completedSteps: [],
  allSteps: [],
  currentStep: null,
};

export const AppContext = React.createContext(defaultState);

const ContextProvider = ({ children }) => {
  const data = useStaticQuery(graphql`
    query AppContextStudyPagesQuery {
      allMdx(
        filter: { frontmatter: { group: { eq: "study" } } }
        sort: { fields: frontmatter___order }
      ) {
        edges {
          node {
            frontmatter {
              order
              title
            }
            id
            fields {
              slug
            }
          }
        }
      }
    }
  `);

  const [state, setState] = React.useState({
    completedSteps: [],
    allSteps: [...data.allMdx.edges],
  });

  React.useEffect(() => {
    const uuid = localStorage.getItem("uuid");
    if (!uuid) {
      localStorage.setItem("uuid", uuidv4());
    }
  }, []);
  return (
    <AppContext.Provider value={{ ...state, setState }}>
      {children}
    </AppContext.Provider>
  );
};

export default ContextProvider;
