import "./src/css/style.css";
import React from "react";

// Load Inter typeface
require("typeface-inter");

// Load Berkshire Swash typeface
require("typeface-berkshire-swash");

import ContextProvider from "./src/context/AppContext";

export const wrapPageElement = ({ element }) => {
  return <ContextProvider>{element}</ContextProvider>;
};
